import {Action, createReducer, on} from '@ngrx/store';
// import the interface
import { Group } from '../models/group.model';
import * as GroupAction from '../actions/group.action';

export const groupFeatureKey = 'group';

export interface GroupState {
    group: Group;
}

export const initialState: GroupState = {
    group : {
        name: null
    }
}

export const groupReducer = createReducer(
    initialState,
    on(GroupAction.addGroup,
            (state: GroupState, {group}) => ({...state, group }))
);


export function reducer(state: GroupState | undefined, action: Action): any {
    return groupReducer(state, action);
}