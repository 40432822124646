import {Action, createReducer, on} from '@ngrx/store';
// import the interface
import { Provider } from '../models/provider.model';
import * as ProviderAction from '../actions/provider.action';

export const providerFeatureKey = 'provider';

export interface ProviderState {
    provider: Provider;
}

export const initialState: ProviderState = {
    provider : {
        id: null
    }
}

export const providerReducer = createReducer(
    initialState,
    on(ProviderAction.addProvider,
            (state: ProviderState, {provider}) => ({...state, provider }))
);


export function reducer(state: ProviderState | undefined, action: Action): any {
    return providerReducer(state, action);
}