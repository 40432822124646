<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="bucklogo" href="#" (click)="navigateHome()">
      <img src="assets/images/logo.png">
    </a>
  </div>
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <div class="row" style="width: 400px;">
    <div class="col-2" style="padding-top: 10px;">
      <img [src] = "user?.picture" width = 50>
    </div>
    <div class="col" style="padding-top: 10px;">
      <div class="row" style="margin-bottom: -10px;">
        <div class="col">
          <label style="font-weight: bold;">Buckzy Payments</label>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <label style="font-size: 12px; font-weight: bold;">Super Admin</label>
        </div>
      </div>
    </div>
    <div class="col-4" style="padding-top: 10px;">
      <!-- <button nbButton status="primary" [nbContextMenu]="userMenu" style="border-radius: 50%;">
        <nb-icon icon="arrow-ios-downward" style="color: white;"></nb-icon>
      </button> -->
      <button nbButton outline ghost status="primary" [nbContextMenu]="userMenu" style="border-radius: 50%;" data-cy="button-arrow-down">
        <nb-icon icon="arrow-ios-downward" ></nb-icon>
      </button>
    </div>
  </div>
</div>
