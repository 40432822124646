import {Action, createReducer, on} from '@ngrx/store';
// import the interface
import { Client } from '../models/client.model';
import * as ClientAction from '../actions/client.action';

export const clientFeatureKey = 'client';

export interface ClientState {
    client: Client;
}

export const initialState: ClientState = {
    client : {
        id: null,
        user: null
    }
}

export const clientReducer = createReducer(
    initialState,
    on(ClientAction.addClient,
            (state: ClientState, {client}) => ({...state, client }))
);


export function reducer(state: ClientState | undefined, action: Action): any {
    return clientReducer(state, action);
}