<nb-card>
    <nb-card-header id="header-text" style="font-weight: bold;">{{header}}!</nb-card-header>
      <nb-card-body style="text-align: center;">
          <label>{{message}}</label>
          <br><br>
        <div class="row show-grid">
          <div class="col"></div>
  
          <div class="col">
            <button *ngIf="yesButtonText" nbButton fullWidth (click)="close()" style="text-align: center;">{{yesButtonText}}</button>
          </div>
  
        </div>
      </nb-card-body>
  </nb-card>

