import { Injectable } from '@angular/core';
import { NbDialogService, NbWindowRef } from '@nebular/theme';
import { formatCurrency } from '@angular/common';
import { AlertBoxComponent } from '../../pages/secure/common-modules/alert-box/alert-box.component';
import { NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private language: any;
  dialogsRef: any;
  windowRef: NbWindowRef;
     
  constructor(private toastrService: NbToastrService, private auth: NbAuthService, public alertController: NbDialogService, public dialogService: NbDialogService, public router: Router) { }

  getErrorText(err: any) {
    // console.log(err);
    
    let errorText = "";
    if (typeof(err)=='string') {
      errorText = err ;
    } else if (typeof(err)=='object') {
      if (err.error.errors) {
        if (err.error.errors.length>0) {
          err.error.errors.forEach(er => {
            errorText += ( "\n\n " + er.message + " " )
          });
        }
        else if(err.error.errors.request) {
          if (err.error.errors.request[0] && err.error.errors.request[0].message) {
            // This is the actual text that we always want to return! 
            // All test above are getting backup values to return in rare cases when err comes back as a non standard object 
            errorText = err.error.errors.request[0].message;
          }
        }
      } else if (err.error && err.error.title) {
        errorText = err.error.title;
      } else if (typeof(err.error.error)=='string' ) {
        errorText = err.error.error;
      } else if (typeof(err.error)=='string' ) {
        errorText = err.error;
      }
    } 
    return errorText;
  }

 getFormattedDate(datetime, format) {
    var day, month;

    try {
      datetime = datetime.replace(/-/g,'/');
    } catch(e) {}    

    var date = new Date(datetime);

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();
      
    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);

    if(format == "yyyy-MM-dd")
      return date.getFullYear() + "-" + month + "-" + day;
    if(format == "yyyy/MM/dd")
      return date.getFullYear() + "/" + month + "/" + day;
    else
      return month + "/" + day + "/" + date.getFullYear();
  }

 getFormattedDateTime(datetime) {
    var day, month, hours, mins, suffix;

    var date = new Date(datetime);

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();
      
    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);
          
    if(date.getHours() > 12) {
      hours = date.getHours() - 12;
      suffix = "PM";
      if(hours.toString().length == 1)
        hours = "0" + hours;
    }
    else {
      suffix = "AM";
      hours = date.getHours();
      if(hours.toString().length == 1)
        hours = "0" + hours;
    }

    if(date.getMinutes().toString().length == 1)
      mins = "0" + date.getMinutes();
    else
      mins = date.getMinutes();

    return month + "/" + day + "/" + date.getFullYear() + " " + hours + ":" + mins + " " + suffix;
  }

  getApiFromDateTime(datetime) {
    var day, month, hours, mins, suffix;

    var date = new Date(datetime);

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();
      
    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);
          
    if(date.getHours() > 12) {
      hours = date.getHours() - 12;
      suffix = "PM";
      if(hours.toString().length == 1)
        hours = "0" + hours;
    }
    else {
      suffix = "AM";
      hours = date.getHours();
      if(hours.toString().length == 1)
        hours = "0" + hours;
    }

    if(date.getMinutes().toString().length == 1)
      mins = "0" + date.getMinutes();
    else
      mins = date.getMinutes();

    return date.getFullYear() + "-" + month + "-" + day + "T" + "00:00:00.000Z";
  }

  getApiToDateTime(datetime) {
    var day, month, hours, mins, suffix;

    var date = new Date(datetime);

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();
      
    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);
          
    if(date.getHours() > 12) {
      hours = date.getHours() - 12;
      suffix = "PM";
      if(hours.toString().length == 1)
        hours = "0" + hours;
    }
    else {
      suffix = "AM";
      hours = date.getHours();
      if(hours.toString().length == 1)
        hours = "0" + hours;
    }

    if(date.getMinutes().toString().length == 1)
      mins = "0" + date.getMinutes();
    else
      mins = date.getMinutes();

    return date.getFullYear() + "-" + month + "-" + day + "T" + "23:59:59.999Z";
  }

  getFromTimeZonedDate(date) {
    var day, month, hours, mins, sec, milSec, timeZone;

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();

    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);

    if(date.getHours().toString().length == 1) 
      hours = "0" + date.getHours();
    else
      hours = date.getHours();

    if(date.getMinutes().toString().length == 1)
      mins = "0" + date.getMinutes();
    else
      mins = date.getMinutes();

    if(date.getSeconds().toString().length == 1)
      sec = "0" + date.getSeconds();
    else
      sec = date.getSeconds();

    if(date.getMilliseconds().toString().length == 1)
      milSec = "00" + date.getMilliseconds();
    else
      milSec = date.getMilliseconds();

      date

    var timezeoneOffset = date.getTimezoneOffset() / 60;
    if(date.getTimezoneOffset() >= 0) {
      if(timezeoneOffset.toString().length == 1) 
        timeZone = "-0" + timezeoneOffset + ":00";
      else {
        var number = timezeoneOffset.toString().split(".");
        var temp = "0." + number[1];
        timeZone = "-0" + parseInt("" + number[0]) + ":" + parseFloat(temp) * 60;
      }
    }
    else {
      timezeoneOffset = Math.abs(timezeoneOffset);
      if(timezeoneOffset.toString().length == 1) 
        timeZone = "+0" + timezeoneOffset + ":00";
      else {
        var number = timezeoneOffset.toString().split(".");
        var temp = "0." + number[1];
        timeZone = "+0" + parseInt("" + number[0]) + ":" + parseFloat(temp) * 60;
      }
    }
  
    return date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + mins + ":" + sec + "." + milSec + timeZone;
  }

  getToTimeZonedDate(date) {
    var day, month, hours, mins, sec, milSec, timeZone;

    if(date.getDate().toString().length == 1)
      day = "0" + date.getDate();
    else
      day = date.getDate();

    if((date.getMonth() + 1).toString().length == 1)
      month = "0" + (date.getMonth() + 1);
    else
      month = (date.getMonth() + 1);

    if(date.getHours() > 0) {
      if(date.getHours().toString().length == 1) 
        hours = "0" + date.getHours();
      else
        hours = date.getHours();
    }
    else 
      hours = 23;

    if(date.getMinutes() > 0) {
      if(date.getMinutes().toString().length == 1)
        mins = "0" + date.getMinutes();
      else
        mins = date.getMinutes();
    }
    else 
      mins = 59;
    
    if(date.getSeconds() > 0) {
      if(date.getSeconds().toString().length == 1)
        sec = "0" + date.getSeconds();
      else
        sec = date.getSeconds();
    }
    else 
      sec = 59

    if(date.getMilliseconds() > 0) {
      if(date.getMilliseconds().toString().length == 1)
        milSec = "00" + date.getMilliseconds();
      else
        milSec = date.getMilliseconds();
    }
    else 
      milSec = 999;

      var timezeoneOffset = date.getTimezoneOffset() / 60;
    if(date.getTimezoneOffset() >= 0) {
      if(timezeoneOffset.toString().length == 1) 
        timeZone = "-0" + timezeoneOffset + ":00";
      else {
        var number = timezeoneOffset.toString().split(".");
        var temp = "0." + number[1];
        timeZone = "-0" + parseInt("" + number[0]) + ":" + parseFloat(temp) * 60;
      }
    }
    else {
      timezeoneOffset = Math.abs(timezeoneOffset);
      if(timezeoneOffset.toString().length == 1) 
        timeZone = "+0" + timezeoneOffset + ":00";
      else {
        var number = timezeoneOffset.toString().split(".");
        var temp = "0." + number[1];
        timeZone = "+0" + parseInt("" + number[0]) + ":" + parseFloat(temp) * 60;
      }
    } 

    return date.getFullYear() + "-" + month + "-" + day + "T" + hours + ":" + mins + ":" + sec + "." + milSec + timeZone;
  }

  escapeCSV (term) {
    if(term != undefined || term != null) {
      if (term.match && term.match(/,|"/))
        return `"${term.replace('"','""')}"`
      else 
        return term
    }
  }

  setLanguage(language) {
    localStorage.setItem("buck_language", language);
  }

  getLanguage() {
    var language = localStorage.getItem("buck_language");
    //localStorage.clear(); // never call this. It will break entire app by deleting all local storage including Authentication user tokens
    return language;
  }

  getMaskedAccountNumber(str) {
    var newStr = '', extraStr = '';
    if(str) {
      str = str.replace(/\s/g, '');

      if(str.length < 5) {
        for(var i = 0; i < (5 - str.length); i++) {
          extraStr = extraStr + '*';
        }
  
        str = extraStr + str;
      }
      else
        extraStr = '';
  
      for(var j = 0; j < (str.length - 4); j++) {
        newStr = newStr + str[j].replace(str[j], "*")
      }
      for(var k = (str.length - 4); k < str.length; k++) {
        newStr = newStr + str[k];
      }
  
      return newStr;
    }
    else
      return null;
  }

  getTwoPlacesAfterDecimal(str) {
    try {
      return parseFloat(str).toFixed(2);
    } catch(e) {
      return str;
    }
  }

  validateDates(startDate, endDate) {
    var errMsg = [], newStartDate = null, newEndDate = null;

    newStartDate = new Date(startDate);
    newEndDate = new Date(endDate);

    if(startDate == null || startDate == undefined) 
      errMsg.push("Please provide Start Date");
    
    if(endDate == null || endDate == undefined) 
      errMsg.push("Please provide Start Date");

    if(newStartDate > newEndDate)
      errMsg.push("The Start Date cannot appear after the End Date");

    return errMsg;
  }

  formatAmount(amount) {
    return parseFloat(amount+"").toLocaleString(undefined,{minimumFractionDigits: 2, maximumFractionDigits: 6})
  }

  formatCount(amount) {
    return parseFloat(amount+"").toLocaleString(undefined,{minimumFractionDigits: 0, maximumFractionDigits: 6})
  }
  formatCurrency(amount) {
    if ( !amount ) { amount = "0.00"; }
    if( !(amount+"").includes(".") ) {
      return amount+".00";
    }
    // return amount+"";
    return parseFloat( '' + amount ).toFixed(2);
  }

  printDate(dateString) {
    return (new Date(dateString).toLocaleString());
  }
  
  copyToClipboard() {
    // this.loading = true;
    var myInput, myLabel, message = '';
    myInput = document.getElementsByClassName("myInput");
    myLabel = document.getElementsByClassName("myLabel");
    // idText.select();
    for(var i = 0; i < myLabel.length; i++) {
      // console.log(myInput.value);
      if(myInput[i].value != '') {
        if(message == '')
          message = myLabel[i].innerText + ": " + myInput[i].value;
        else
          message = message + ", " + myLabel[i].innerText + ": " + myInput[i].value;
      }
      else {
        if(message == '')
          message = myLabel[i].innerText;
        else
          message = message + ", " + myLabel[i].innerText;
      }
    }
    this.copyText(message);
  }

  copyText(val: string){
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  generatePwd(passwordLength) {
    var randomChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()_+\-=\[\]{};:\\|,.<>\/?]+';
    var result = '';
    for ( var i = 0; i < passwordLength; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
  }

  encodeSpecialCharacterInUsername(str) {
    var text = null, temp = null;
    temp = str.split('@');
    if(temp.length > 1)
      text = encodeURIComponent(temp[0]) + '@' + temp[1];
    else if(temp.length == 1)
      text = encodeURIComponent(temp[0]);
    return text;
  }

  formatAmountNumber(amount) {
    amount = formatCurrency(amount, 'en-US', '', '1.2-2');
    return amount;
  }

  showAlert(title: string, messageTxt: string) {
    alert(messageTxt);
  }
  
  showAlertBox() {
    this.dialogsRef = this.dialogService.open(AlertBoxComponent, { context: {}, })
    .onClose.subscribe(response => {
      // console.log(response);
      this.logout();
    })
  }

  logout() {
    this.auth.logout('email').subscribe(result => {
      this.router.navigateByUrl('/auth/login');
    }, err => {
      console.log(err);
      this.router.navigateByUrl('/auth/login');
    });
  }

  checkTokenValidity(token) {
    var tokenExpiryDate, currentDate;
    tokenExpiryDate = token;
    currentDate = new Date();

    // console.log(new Date(expiredDate));
    // console.log(new Date(currentDate));

    if(tokenExpiryDate > currentDate)
      return true;
    else if(tokenExpiryDate <= currentDate)
      return false;
    else
      return false;
  }

  getErrorsFromAPI(err) {
    var error = [];

    for(var i = 0; i < err?.error?.errors?.length; i++) {
      error.push(err?.error?.errors[i]?.message);
    }

    if(error.length == 0) {
      error.push(err?.error?.message);
    }

    return error;
  }

  showToast(title, msg, duration) {
    var status = 'success'
    this.toastrService.show(
      msg,
      title,
      { status, duration });
  }

  removeNullAndUndefined(str) {
    if(!str)
      str = '- - -';

    return str;
  }
}
