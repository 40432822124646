import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';
import { NbAuthService, NbAuthToken } from '@nebular/auth';
import { catchError, switchMap } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
import { HelperService } from '../helper/helper.service';
import { Router } from '@angular/router';
import { env } from 'process';

@Injectable()
export class ApiKeyHttpInterceptor implements HttpInterceptor {

    token: string;
    unAuthorizedError: number = 401;
    constructor(private auth: NbAuthService, private helperService: HelperService, private nbAuthService: NbAuthService, private router: Router ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        //console.log('interceptor triggered! URL: ' + request.url);
        // this.checkToken();
        let authHeader = 'Basic ' + btoa(environment.AUTH.CLIENT_ID + ':' + environment.AUTH.CLIENT_PWD);
        if ( request.url !== environment.AUTH.AUTH_BASE + environment.AUTH.AUTH_URL ) {
            let apiKey = environment.AUTH.API_KEY;
            if(!request.url.startsWith(environment.BASE_URL)){
                apiKey = environment.AUTH.API_KEY_OLD;
            }
            return this.auth.getToken().pipe(switchMap((token: NbAuthToken) => {
                authHeader = 'Bearer ' + token.getValue();
                request = request.clone({
                    setHeaders: {
                        'Authorization' : authHeader,
                    },
                    setParams: {
                        apikey: apiKey,
                    },
                });
                return next.handle(request).pipe(
                    catchError((error) => {
                        if (
                            error instanceof HttpErrorResponse &&
                            !request.url.includes(environment.AUTH.AUTH_URL) &&
                            !this.router.url.includes('auth/login') &&
                            error.status === this.unAuthorizedError
                        ) {
                            // try using refresh token strategy (if not expired) to obtain access token
                            // and log user out if valid auth token cannot be obtained 
                            this.nbAuthService.isAuthenticatedOrRefresh().subscribe(
                                (isValid) => {
                                    if (!isValid) {
                                        this.helperService.showAlertBox();
                                    } else {
                                        return;
                                    }
                                },
                                (err) => {
                                    this.helperService.showAlertBox();
                                }
                            );
                        }
                        return throwError(error);
                    })
                );
            }));
        }else{
            request = request.clone({
                setHeaders: {
                    'Authorization' : authHeader,
                },
                setParams: {
                    apikey: environment.AUTH.API_KEY,
                },
            });
            return next.handle(request);
        }

    }
}
