import {Action, createReducer, on} from '@ngrx/store';
// import the interface
import { Filter } from '../models/filters.model';
import * as FilterAction from '../actions/filters.action';

export const filterFeatureKey = 'filters';

export interface FilterState {
    filter: Filter;
}

export const initialState: FilterState = {
    filter : {
        transaction: {
            status: null
        }
    }
}

export const filterReducer = createReducer(
    initialState,
    on(FilterAction.addFilter,
            (state: FilterState, {filter}) => ({...state, filter }))
);


export function reducer(state: FilterState | undefined, action: Action): any {
    return filterReducer(state, action);
}